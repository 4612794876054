//@ts-check
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import {
  getMonthDaysInfo,
  isSameDay,
  MONTHS,
  DAY_NAME_BY_NUMBER,
  mergeHours,
  dateToStringV2
} from 'utils/date'
import { IconButton } from '@material-ui/core'
import { findAgoraAcademicSchedules } from 'api/academicSchedules'
import styles from './Schedule.module.css'

const DayCell = ({ day, selectedMonth, onGetHoursByDate }) => {
  let dayClassName = 'date_identifier'
  let cellClassName = 'calendar_day'
  if (day.getMonth() === selectedMonth) dayClassName += ' active'
  if (isSameDay(new Date(), day)) {
    dayClassName += ' today'
    cellClassName += ' today'
  }
  return (
    <td className={cellClassName}>
      <div className={dayClassName}>{day.getDate()}</div>
      <div className={styles.hourList}>
        {mergeHours(onGetHoursByDate(day)).map((hour, i) => (
          <p key={i} className={styles.hourListItem}>
            {hour}
          </p>
        ))}
      </div>
    </td>
  )
}
const WeekRow = ({ week, selectedMonth, onGetHoursByDate }) => {
  return (
    <tr className='calendar_week'>
      {week.map((day, i) => (
        <DayCell
          key={i}
          day={day}
          selectedMonth={selectedMonth}
          onGetHoursByDate={onGetHoursByDate}
        />
      ))}
    </tr>
  )
}

function Schedule() {
  const [state, setState] = useState({
    selectedYear: new Date().getFullYear(),
    selectedMonth: new Date().getMonth(),
    academicSchedule: [],
    isFetching: false
  })

  const { selectedYear, selectedMonth, academicSchedule } = state

  const weeks = useMemo(
    () => getMonthDaysInfo(selectedYear, selectedMonth),
    [selectedYear, selectedMonth]
  )

  const changeMonth = useCallback(
    amount => {
      let newYear = selectedYear
      let newMonth = selectedMonth + amount
      if (newMonth === 12) {
        newMonth = 0
        newYear++
      } else if (newMonth === -1) {
        newMonth = 11
        newYear--
      }
      setState(state => ({
        ...state,
        selectedYear: newYear,
        selectedMonth: newMonth
      }))
    },
    [selectedMonth, selectedYear]
  )

  const handleGetHoursByDate = selectedDate => {
    const selectedDaySchedule = academicSchedule.find(schedule => {
      if (isSameDay(new Date(schedule.date), selectedDate)) return schedule
    })

    if (!selectedDaySchedule) {
      return []
    }

    return selectedDaySchedule.hours
  }

  const fetchData = useCallback(async () => {
    try {
      setState(prevState => ({ ...prevState, isFetching: true }))
      const startDate = dateToStringV2(
        new Date(state.selectedYear, state.selectedMonth, 1)
      )
      const endDate = dateToStringV2(
        new Date(state.selectedYear, state.selectedMonth + 1, 1)
      )

      const academicSchedule = await findAgoraAcademicSchedules(
        startDate,
        endDate
      )

      setState(prevState => ({
        ...prevState,
        academicSchedule,
        isFetching: false
      }))
    } catch (error) {
      console.error('Error fetching academicSchedule: ', error)
      setState(prevState => ({ ...prevState, isFetching: false }))
    }
  }, [state.selectedMonth, state.selectedYear])

  useEffect(() => {
    fetchData()
  }, [fetchData])

  return (
    <div>
      <div className={styles.calendarContainer}>
        <div>
          <div>
            <IconButton onClick={() => changeMonth(-1)}>
              <i className='material-icons'>arrow_back</i>
            </IconButton>
            <h2 className='calendar_month_name'>{`${MONTHS[selectedMonth]} de ${selectedYear}`}</h2>
            <IconButton onClick={() => changeMonth(1)}>
              <i className='material-icons'>arrow_forward</i>
            </IconButton>
          </div>
          <table className={styles.calendarElement}>
            <thead>
              <tr>
                {DAY_NAME_BY_NUMBER.map(({ label, day }) => {
                  return (
                    <th key={day} className={styles.calendarElementHeader}>
                      {label}
                    </th>
                  )
                })}
              </tr>
            </thead>
            <tbody>
              {weeks.map((week, i) => (
                <WeekRow
                  key={i}
                  week={week}
                  selectedMonth={selectedMonth}
                  onGetHoursByDate={handleGetHoursByDate}
                />
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  )
}

export default Schedule
