import { GET } from 'api'

export const getAvailableHoursBy = (date, teacherId) =>
  GET({
    url: `admin/teacher-schedule/available-hours-by?date=${new Date(
      date
    ).toDateString()}&teacherId=${teacherId}`
  })

export const getTeacherSchedule = ({
  teacherId,
  year = new Date().getFullYear(),
  month = new Date().getMonth()
}) =>
  GET({
    url: `admin/teacher-schedule/list?teacherId=${teacherId}&month=${month}&year=${year}`
  })
