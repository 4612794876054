//@ts-check
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { assoc, groupBy, omit, pick, prop, sortBy, times, uniq } from 'ramda'
import { IconButton, TextField, Tooltip } from '@material-ui/core'
import { KeyboardDatePicker } from '@material-ui/pickers'
import { Link } from 'react-router-dom'
import {
  findAttendanceDTOs,
  updateTestAttendance,
  updateAttendance
} from 'api/attendances'
import { toTimeString } from 'utils/date'
import InformationTable from 'components/InformationTable/InformationTable'
import {
  AttendanceSources,
  AttendanceStatus,
  AttendanceStatusLabels,
  AttendanceTypeLabels,
  AttendanceTypes,
  SubjectTypes,
  SubjectLevels
} from 'shared/constants'
import DefaultSelect from 'shared/DefaultSelect'
import { useMessageSnackbarActions } from 'elements/MessageContext/MessageContext'
import AttendanceHourSelector from './AttendanceHourSelector'
import styles from './Planning.module.css'

const MAX_VISIBLE_LENGTH = 25
const iconPropsByAttendanceStatus = {
  [AttendanceStatus.IMPARTED]: { icon: 'check', color: 'primary' },
  [AttendanceStatus.ABSENCE]: {
    icon: 'close',
    color: 'inherit',
    style: { color: 'red' }
  },
  [AttendanceStatus.JUSTIFIED_ABSENCE]: {
    icon: 'highlight_off',
    color: 'secondary'
  }
}
const customLabels = {
  ...SubjectTypes,
  ...pick(['IDIOMAS'], SubjectLevels)
}

function StatusComponent({ status, disabled, onClick }) {
  if (status === AttendanceStatus.PLANNED) {
    return Object.keys(
      omit(['planned', 'canceled_by_app'], AttendanceStatusLabels)
    ).map(key => {
      const { icon, color, style = {} } = iconPropsByAttendanceStatus[key]
      return (
        <IconButton
          disabled={disabled}
          key={key}
          onClick={() => onClick(key)}
          // @ts-ignore
          color={color}
          style={{ ...style }}
          title={AttendanceStatusLabels[key]}
        >
          <i className='material-icons md-red'>{icon}</i>
        </IconButton>
      )
    })
  }
  const { icon, color, style = {} } = iconPropsByAttendanceStatus[status] || {}

  return (
    <IconButton
      // @ts-ignore
      color={color}
      disabled={disabled}
      style={{ ...style }}
      title={AttendanceStatusLabels[status]}
      onClick={() => onClick(AttendanceStatus.PLANNED)}
    >
      <i className='material-icons md-red'>{icon}</i>
    </IconButton>
  )
}

function spreadAttendancesByHours(attendances) {
  const result = {}

  const padTime = num => num.toString().padStart(2, '0')

  attendances.forEach(attendance => {
    const classStart = new Date(attendance.classStart)
    const classEnd = new Date(attendance.classEnd)
    let currentTime = new Date(classStart)

    while (currentTime < classEnd) {
      const hours = padTime(currentTime.getHours())
      const minutes = padTime(currentTime.getMinutes())
      const targetHour = `${hours}:${minutes}`

      if (!result[targetHour]) {
        result[targetHour] = []
      }
      result[targetHour].push({ ...attendance, targetHour })
      currentTime.setHours(currentTime.getHours() + 1)
    }
  })

  return result
}

const getStyle = (attendanceDTO = {}) => {
  if (attendanceDTO.type !== AttendanceTypes.TEST) return
  if (attendanceDTO.source === AttendanceSources.STUDENT)
    return { background: 'var(--purpletronic-20)' }
}

export function makeInitialHour(date = new Date()) {
  const aux = new Date(date)
  const hour = aux.getHours()

  if (hour < 15) date.setMinutes(0, 0, 0)
  if (hour >= 15) date.setMinutes(30, 0, 0)
  return toTimeString(date)
}

function Planning() {
  const [state, setState] = useState({
    selectedHour: makeInitialHour(),
    selectedDate: new Date(),
    attendances: [],
    isFetching: false,
    selectedTeacher: ''
  })
  const { setSuccessMessage, setErrorMessage } = useMessageSnackbarActions()
  const fetchAttendances = useCallback(() => {
    // @ts-ignore
    if (isNaN(Date.parse(state.selectedDate))) return
    setState(assoc('isFetching', true))
    findAttendanceDTOs({ date: state.selectedDate })
      .then(attendances => {
        setState(state => ({
          ...state,
          attendances,
          isFetching: false
        }))
      })
      .catch(e => {
        console.error('Error fetching attendances by date: ', e)
        setState(assoc('isFetching', false))
      })
  }, [state.selectedDate])

  const attendancesByHour = useMemo(() => {
    return spreadAttendancesByHours(
      state.selectedTeacher
        ? state.attendances.filter(
            ({ teacherName }) => state.selectedTeacher === teacherName
          )
        : state.attendances
    )
  }, [state.attendances, state.selectedTeacher])

  const handleSelectHour = useCallback(stringHour => {
    setState(assoc('isFetching', true))
    setTimeout(() => {
      setState(assoc('selectedHour', stringHour))
      setState(assoc('isFetching', false))
    }, 150)
  }, [])

  const handleChangeDate = useCallback(date => {
    setState(assoc('selectedDate', date))
  }, [])
  const handleEditAttendance = useCallback(
    newAttendance => {
      if (newAttendance.type === AttendanceTypes.TEST)
        return updateTestAttendance(newAttendance.id, newAttendance)
          .then(data => {
            setSuccessMessage('Actualizado correctamente')
          })
          .catch(e => {
            console.error('Error updating status', e)
            setErrorMessage(e.message || 'No se ha podido actualizar')
          })
          .finally(() => fetchAttendances())
      updateAttendance(newAttendance.id, omit(['id'], newAttendance))
        .then(data => {
          setSuccessMessage('Actualizado correctamente')
        })
        .catch(e => {
          console.error('Error updating status', e)
          setErrorMessage(e.message || 'No se ha podido actualizar')
        })
        .finally(() => fetchAttendances())
    },
    [fetchAttendances, setErrorMessage, setSuccessMessage]
  )

  useEffect(() => {
    fetchAttendances()
  }, [fetchAttendances, state.selectedDate])

  return (
    <>
      <AttendanceHourSelector
        selectedHour={state.selectedHour}
        attendancesByHour={attendancesByHour}
        onSelectHour={handleSelectHour}
      />
      <div className={styles.innerContainer}>
        <InformationTable
          className={styles.table}
          size='small'
          isFetching={state.isFetching}
          details={[
            {
              title: 'Duración',
              key: 'range',
              align: 'left',
              style: {
                width: 80
              }
            },
            { title: 'Alumno', key: 'studentName', align: 'left' },
            { title: 'Asignatura', key: 'subjectName', align: 'left' },
            { title: 'Profesor', key: 'teacherName', align: 'left' },
            {
              title: 'Tipo clase',
              key: 'type',
              align: 'left',
              style: {
                width: 95
              }
            },
            { title: 'Estado', key: 'status', align: 'center' },
            {
              title: 'Observaciones',
              key: 'description',
              style: {
                width: 300
              }
            }
            // {
            //   title: 'Comentarios alumno',
            //   key: 'studentComments'
            // }
          ]}
          data={sortBy(
            prop('classStart'),
            attendancesByHour[state.selectedHour] || []
          )?.map(attendanceDTO => ({
            range: (
              <span
                onClick={() => navigator.clipboard.writeText(attendanceDTO?.id)}
              >
                {toTimeString(attendanceDTO.classStart)} -
                {toTimeString(attendanceDTO.classEnd)}
              </span>
            ),
            studentName: (
              <Link to={`/students/${attendanceDTO.studentId}`}>
                <Tooltip
                  title={
                    <h3>{`${toTimeString(
                      attendanceDTO.classStart
                    )} - ${toTimeString(attendanceDTO.classEnd)}`}</h3>
                  }
                  arrow
                >
                  <span>{attendanceDTO.studentName}</span>
                </Tooltip>
              </Link>
            ),
            subjectName:
              attendanceDTO.type === AttendanceTypes.TEST ? (
                <span>{attendanceDTO.subjectName}</span>
              ) : (
                <Tooltip
                  title={
                    <div style={{ textAlign: 'center' }}>
                      <h3>{attendanceDTO.subscriptionName}</h3>
                    </div>
                  }
                  arrow
                >
                  <span style={{ cursor: 'pointer' }}>
                    {attendanceDTO.subjectName}
                  </span>
                </Tooltip>
              ),
            teacherName: attendanceDTO.teacherName,
            type: AttendanceTypeLabels[attendanceDTO.type],
            status: (
              // @ts-ignore
              <StatusComponent
                status={attendanceDTO.status}
                onClick={newStatus =>
                  handleEditAttendance({
                    ...attendanceDTO,
                    status: newStatus
                  })
                }
                disabled={state.isFetching}
              />
            ),
            description: !attendanceDTO.description ? (
              <TextField
                size='small'
                defaultValue={attendanceDTO.description}
                onBlur={e =>
                  handleEditAttendance({
                    ...attendanceDTO,
                    description: e.target.value
                  })
                }
                fullWidth
              />
            ) : (
              <Tooltip title={attendanceDTO.description || ''} arrow>
                <TextField
                  size='small'
                  defaultValue={attendanceDTO.description}
                  onBlur={e =>
                    handleEditAttendance({
                      ...attendanceDTO,
                      description: e.target.value
                    })
                  }
                  fullWidth
                />
              </Tooltip>
            ),
            studentComments: (
              <Tooltip title={attendanceDTO.studentComments || ''} arrow>
                <span>
                  {attendanceDTO.studentComments
                    ? attendanceDTO.studentComments.substring(
                        0,
                        MAX_VISIBLE_LENGTH
                      ) + '...'
                    : ''}
                </span>
              </Tooltip>
            ),
            style: getStyle(attendanceDTO)
          }))}
        />
        <div className={styles.footer}>
          <div className={styles.subjectTypeLabels}>
            {Object.keys(customLabels).map((type, i) => (
              <div
                key={i}
                className={[styles.typeItem, styles[type]].join(' ')}
              >
                {customLabels[type]}
              </div>
            ))}
          </div>
          <span className={styles.selectedDate}>
            Clases del día{' '}
            {`${state.selectedDate?.toLocaleDateString()} a las ${
              state.selectedHour
            }:00`}
          </span>
          <div className={styles.teacherFilter}>
            <DefaultSelect
              title='Filtrar por profesor'
              value={state.selectedTeacher}
              margin='none'
              options={sortBy(
                prop('name'),
                uniq(
                  state.attendances?.map(({ teacherName }) => ({
                    id: teacherName,
                    name: teacherName
                  }))
                )
              )}
              onChange={e =>
                setState(state => ({
                  ...state,
                  selectedTeacher: e.target.value
                }))
              }
            />
          </div>

          <KeyboardDatePicker
            format='dd/MM/yyyy'
            value={state.selectedDate || null}
            onChange={handleChangeDate}
          />
        </div>
      </div>
    </>
  )
}

export default Planning
